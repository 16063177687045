import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import BgImage from "../../images/GardenBig.jpeg"
import Layout from "../../components/layoutPhoenixGarden"
import PageHeaders from "../../components/pageHeaders"
import SEO from "../../components/seo"

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
`

const IndexPage = () => (
  <Layout
    ImgSrc={BgImage}
    MainText="Cross Community Garden"
    SecondText="Let's Grow Together"
  >
    <SEO title="Cross Community Garden" />
    <PageHeaders
      css={css`
        display: flex;
      `}
    >
      What is the Cross Community Garden?
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      Our community garden is located on the campus of Cross Church Phoenix and
      is a space to learn, to grow, and to have community with one another.
      <br />
      <br />
      There are two aspects of our community garden:
      <ul>
        <li>
          Community Wide: Community Garden work days are great learning
          opportunities to get basics of gardening, healthy eating, outdoor
          activity, and community work with others.{" "}
          <a href="https://fb.com/crosschurchphx">Click here</a> for our next
          date.
        </li>
        <li>
          Personal: Individual garden bed rentals are available for $15/month
          that an individual or household pays a monthly fee to have their own
          garden plot that is their exclusive responsibility for maintaining and
          for reaping the harvest (water included). You do the work and you get
          all the results!{" "}
          <a href="https://cccares.formstack.com/forms/gardenbedrental">
            Click here
          </a>{" "}
          to reserve your bed.
        </li>
      </ul>{" "}
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      When can I access the Garden?
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      <ul>
        <li>Community Work Days</li>
        <ul>
          <li>Saturday mornings every month</li>
          <li>
            Follow Cross Church Phoenix on social media below or email us at{" "}
            <a href="mailto:info@crosschurchcares.com">
              info@crosschurchcares.com
            </a>{" "}
            to register for the Cross Church Phoenix Newsletter.
          </li>
        </ul>
      </ul>
      <br />
      <ul>
        <li>Rented Personal Garden Beds</li>
        <ul>
          <li>
            Individuals or households will be granted ability for special
            acccess to garden to work as needed.
          </li>
        </ul>
      </ul>
    </ParagraphText>
  </Layout>
)

export default IndexPage
